import { IFavicon } from "@/interfaces/seo";

// APP PUBLIC URL
export const APP_PUBLIC_URL: string[] = [
  "/", 
  "/auth/signin",
  "/auth/forget-password",
  "/auth/set-new-password",
  "/auth/incident/*",
  "/auth/documentation/*"
]

// APP CONFIG
export const APP_MODULE = process.env.NEXT_PUBLIC_APP_MODULE || "Helpdesk";
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL || "https://helpdesk.scanow.fr";
export const APP_COLOR = process.env.NEXT_PUBLIC_APP_COLOR || "#FFFFFF";

  
// META CONFIG
export const APP_TITLE = process.env.NEXT_PUBLIC_APP_NAME || "Scanow";
export const APP_DESCRIPTION = process.env.NEXT_PUBLIC_APP_DESCRIPTION || "Transformez le service desk de votre entreprise, inventoriez vos équipements et assurez une assistance simple, rapide et flexible.";
export const APP_TYPE = process.env.NEXT_PUBLIC_APP_TYPE || "website";
export const APP_OG_IMAGE_URL = process.env.NEXT_PUBLIC_APP_OG_IMAGE_URL || "/images/logo.png";
export const APP_404_IMAGE_URL = process.env.NEXT_PUBLIC_APP_404_IMAGE_URL || "/images/404.png";
export const APP_LOGO = process.env.NEXT_PUBLIC_APP_LOGO || "/images/logo.png";
export const APP_FAVICON = process.env.NEXT_PUBLIC_APP_FAVICON || "/images/favicon.ico";
export const APP_PUBLIC_ROBOTS = process.env.NEXT_PUBLIC_APP_ROBOTS || "index, follow";
export const APP_PRIVATE_ROBOTS = process.env.NEXT_PUBLIC_APP_ROBOTS || "noindex, nofollow";

// FAVICON CONFIG
export const APP_FAVICONS: Array<IFavicon> = [
    {
      rel: "apple-touch-icon",
      sizes: "57x57",
      href: "/images/favicon/apple-icon-57x57.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "60x60",
      href: "/images/favicon/apple-icon-60x60.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "72x72",
      href: "/images/favicon/icon-72x72.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      href: "/images/favicon/apple-icon-76x76.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "114x114",
      href: "/images/favicon/apple-icon-114x114.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      href: "/images/favicon/apple-icon-120x120.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "144x144",
      href: "/images/favicon/icon-144x144.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: "/images/favicon/apple-icon-152x152.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/images/favicon/apple-icon-180x180.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: "/images/favicon/android-icon-192x192.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/images/favicon/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: "/images/favicon/icon-96x96.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/images/favicon/favicon-16x16.png",
    },
    {
      rel: "manifest",
      href: "/images/favicon/manifest.json",
    },
  ];

export const IMAGE_PLACEHOLDER = "/images/no_img.png";
export const AVATAR_PLACEHOLDER = "/images/no_avatar.png";
