import React, { useState, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { MobileNavigation } from "@/components/Navigation";
import { Button } from "@scanow/ui";
import { HiBars3 } from "react-icons/hi2";

export interface IDrawer {}
export const Drawer = ({}: IDrawer) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenMenu = () => setIsOpen(true);
  //const handleCloseMenu = () => setIsOpen(false);
  function handleCloseMenu() {
    setIsOpen(false);
  }

  const renderContent = () => {
    return (
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto md:invisible "
          open={isOpen}
          onClose={handleCloseMenu}
        >
          <div className="fixed left-0 top-0 bottom-0 w-full  z-max outline-none focus:outline-none">
            <React.Fragment>
              <Transition.Child
                as={Fragment}
                enter="transition duration-100 transform"
                enterFrom="opacity-0 -translate-x-14"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-150 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 -translate-x-14"
              >
                <div className="z-10 relative" 
                 onClick={(e)=>{
                  e.preventDefault()
                  handleCloseMenu()
                }}>
                  <MobileNavigation onClickClose={handleCloseMenu} />
                </div>
              </Transition.Child>
 
              <Transition.Child
                as={Fragment}
                enter=" duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave=" duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
              </Transition.Child>
            </React.Fragment>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <Button.Circle
        onClick={handleOpenMenu} >
        <HiBars3 />
      </Button.Circle>
      {renderContent()}
    </>
  );
};
