import {IMegaItem} from "@/interfaces/navigation";

export const FooterNavigationItem = (menu: IMegaItem, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.items.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };