
import { gql } from "@apollo/client";

export const GET_DASHBOARD_DATA = gql`
query GetDashboardData($company_id: uuid!) {
    company: administration_company_by_pk(id: $company_id) {
      id
      name
      setup
      created_at
      logo_url
      licence_count
      licence_end_date
      licence_option_control
      licence_option_documentation
      incidents(order_by: {created_at: desc}, limit: 10){
        resource{
          name
        }
        equipment{
          name
        }
        closed
        user{
          firstname
          lastname
        }
        id
        created_at
        type{
          name
        }
      }
      default_team {
        id
        name
        mail
        phone
        users(where: {deleted: {_eq: false}}) {
          id
          firstname
          lastname
          email
          avatar_url
        }
      }
      resources_aggregate(where: {deleted: {_eq: false}}) {
        aggregate {
          count
        }
      }
      categories_aggregate: resource_types_aggregate {
        aggregate {
          count
        }
      }
      equipments_aggregate(where: {deleted: {_eq: false}}) {
        aggregate {
          count
        }
      }
      locations_aggregate{
        aggregate{
          count
        }
      }
      incidents_aggregate(where: {company_id: {_eq: $company_id}}) {
        aggregate {
          count
        }
      }
    }
  }
  
  
`