import React from "react";
import { PrivateLayout, PublicLayout } from "@/components/Main";

interface ILayout {
  layout: "public" | "private";
  children: React.ReactNode;
  headBar?: React.ReactNode;
}

export default function Layout({ children, layout, headBar }: ILayout) {
  return (
    <div className={"h-full text-neutral-900 dark:text-neutral-400 text-base"}>
      {layout === "private" ? (
        <PrivateLayout headBar={headBar}>{children}</PrivateLayout>
      ) : (
        <PublicLayout>{children}</PublicLayout>
      )}

    </div>
  );
}


