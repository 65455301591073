import React from "react";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ISettingsIncident } from "@scanow/services";
import { dateFormat } from "@scanow/helpers";
import { Avatar } from "@scanow/ui";
import { useRouter } from "next/router";
import { HiOutlineBell } from "react-icons/hi2";

export interface INotification {
  className?: string;
  // incidents: ISettingsIncident[];
}

const notifications = [
  {
    id: "845",
    user: "John Doe",
    avatar_url: "",
    description: "Measure actions your users take",
    time: "3 minutes ago",
    href: "#",
  },
  {
    id: "874",
    user: "Allan Smith",
    avatar_url: "",
    description: "Create your own targeted content",
    time: "1 minute ago",
    href: "#",
  },
  {
    id: "966",
    user: "Jane Doe",
    avatar_url: "",
    description: "Keep track of your growth",
    time: "3 minutes ago",
    href: "#",
  },
];

export const DropdownNotification = ({ className = "" }: INotification) => {
  const router = useRouter();

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="cursor-pointer relative flex justify-center items-center rounded-full w-9 h-9 bg-neutral-200 hover:bg-neutral-300 text-neutral-700 dark:bg-neutral-800 dark:text-neutral-300 dark:hover:bg-neutral-700 duration-300 transition ease-in-out px-2 py-1 text-normal">
              <span className="w-2 h-2 bg-danger-500 absolute top-2 right-3 rounded-full"></span>
              <HiOutlineBell />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-xs sm:max-w-sm px-4 mt-3 -right-28 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <ul className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7">
                    <h3 className="text-xl font-semibold">Notifications</h3>
                    {notifications.map((notification, index) => (
                      <li key={"notif_" + notification.user}>
                        <a
                          // key={index}
                          // href={item.href}
                          onClick={() => {
                            router.push(`/admin/incident/${notification.id}`);
                          }}
                          className="flex px-4 py-2 -m-3 transition duration-300 ease-in-out rounded-lg hover:bg-cyan-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative"
                        >
                          <Avatar
                            appendClassName="w-9 h-9"
                            src={
                              notification.avatar_url || "/images/no_avatar.png"
                            }
                            alt={
                              notification.user
                                ? `${notification.user}`
                                : "John Doe"
                            }
                            title={
                              notification.user
                                ? `${notification.user}`
                                : "John Doe"
                            }
                          />
                          <div className="ml-3 sm:ml-4 space-y-1">
                            <h4 className="text-sm font-medium text-gray-900 dark:text-gray-200">
                              Incident #{notification.id}
                            </h4>
                            <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                              {notification.description}
                            </p>
                            <small className="text-xs text-gray-400 dark:text-gray-400">
                              {notification.time}
                            </small>
                          </div>
                          <span className="absolute right-4 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-error-500"></span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};