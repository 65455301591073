import Head from "next/head";
import { useRouter } from "next/router";
import { ISeo } from "@/interfaces/seo";
import moment from "moment-timezone";
import {
  APP_NAME,
  APP_AUTHOR
} from "@scanow/config";
import {
  APP_MODULE,
  APP_TITLE,
  APP_DESCRIPTION,
  APP_COLOR,
  APP_FAVICONS,
  APP_URL,
  APP_PUBLIC_ROBOTS,
  APP_PRIVATE_ROBOTS,
  APP_TYPE,
  APP_OG_IMAGE_URL
} from "config";
import userAuth from "@/helpers/auth";

const today = moment().tz("Europe/Paris").format("DD/MM/YYYY HH:mm");

export default function Seo({
  postDate,
  pageTitle,
  pageDescription,
  pageType,
  pageImage,
  postAuthor,
}:ISeo) {
  const router = useRouter();
  const auth = userAuth()

  return (
    <Head>
      <title>{`${pageTitle + " | "}  ${APP_NAME} ${APP_MODULE}`}</title>
      <meta
        name="robots"
        content={APP_PRIVATE_ROBOTS }
      />
      <meta name="description" content={pageDescription || APP_DESCRIPTION} />
      <meta property="og:url" content={`${APP_URL}${router.asPath}`} />
      <link rel="canonical" href={`${APP_URL}${router.asPath}`} />
      {/* Open Graph */}
      <meta property="og:type" content={pageType || APP_TYPE} />
      <meta property="og:site_name" content={APP_NAME} />
      <meta property="og:title" content={pageTitle || APP_TITLE} />
      <meta
        property="og:description"
        content={pageDescription || APP_DESCRIPTION}
      />
      <meta
        name="image"
        property="og:image"
        content={pageImage || APP_OG_IMAGE_URL}
      />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={APP_NAME} />
      <meta name="twitter:title" content={pageTitle || APP_TITLE} />
      <meta
        name="twitter:description"
        content={pageDescription || APP_DESCRIPTION}
      />
      <meta name="twitter:image" content={pageImage || APP_OG_IMAGE_URL} />
      {postDate && (
        <>
          <meta property="article:published_time" content={postDate || today} />
          <meta
            name="publish_date"
            property="og:publish_date"
            content={postDate || today}
          />
          <meta
            name="author"
            property="article:author"
            content={postAuthor || APP_AUTHOR}
          />
        </>
      )}
      {/* Favicons */}
      {APP_FAVICONS.map((links) => (
        <link key={links.href} {...links} />
      ))}
      <meta name="msapplication-titlecolor" content={APP_COLOR} />
      <meta name="application-name" content={APP_NAME} />
      <meta
        name="msapplication-tilesmall"
        content="/images/favicon/ms-icon-70x70.png"
      />
      <meta
        name="msapplication-tilemedium"
        content="/images/favicon/icon-144x144.png"
      />
      <meta
        name="msapplication-tilewide"
        content="/images/favicon/ms-icon-310x150.png"
      />
      <meta
        name="msapplication-tilelarge"
        content="/images/favicon/ms-icon-310x310.png"
      />
    </Head>
  );
}
