import {useQuery} from '@apollo/client';
import {useAuth} from '@/helpers/contexts/auth';
import {useEffect, useState} from 'react';
import {
    SCANOW_HELPDESK_GET_LICENSE_QUERY,
    IScanowHelpdeskLicense,
    IScanowLicense,
    IScanowLicenseSelector,
} from '@scanow/helpers';
import {APPLICATIONS} from "@scanow/config";

const allowedLicenses = [APPLICATIONS.Helpdesk];

export default function useLicense() {
    const {auth} = useAuth();
    const {data, loading, error} = useQuery(SCANOW_HELPDESK_GET_LICENSE_QUERY, {
        variables: {
            company_id: auth?.user?.company_id || '',
        },
        skip: !auth?.user?.company_id,
    });
    // null assumes that the user has no license, or that the query is still loading
    const [helpdeskLicense, setHelpdeskLicense] = useState<IScanowLicenseSelector<IScanowHelpdeskLicense> | null>(null);

    const [helpdeskRemainingResources, setHelpdeskRemainingResources] = useState<number | null>(null);
    const [helpdeskOptionDocumentation, setHelpdeskOptionDocumentation] = useState<boolean | null>(null);
    const [helpdeskOptionControl, setHelpdeskOptionControl] = useState<boolean | null>(null);

    const [helpdeskCanCreateResources, setHelpdeskCanCreateResources] = useState<boolean | null>(null);
    const [helpdeskLicenseEndDate, setHelpdeskLicenseEndDate] = useState<Date | null>(null);
    const [helpdeskLicenseEndInDays, setHelpdeskLicenseEndInDays] = useState<number | null>(null);


    useEffect(() => {
        if (data && data.administration_licence) {
            const licenses = data.administration_licence;
            const helpdeskLicenses = licenses.filter((license) => allowedLicenses.includes(license.licence.application));
            if (helpdeskLicenses.length === 0) {
                setHelpdeskLicense(null);
                return;
            }

            const lastToExpire = helpdeskLicenses.sort((a, b) => {
                const aDate = new Date(a.expires_at);
                const bDate = new Date(b.expires_at);
                return aDate.getTime() - bDate.getTime();
            })[0] as IScanowLicenseSelector<IScanowHelpdeskLicense>;

            setHelpdeskLicense(lastToExpire);

            const remainingResources = lastToExpire.licence.max_resources - data.administration_company_by_pk.resources_aggregate.aggregate.count;
            setHelpdeskRemainingResources(Math.max(remainingResources, 0));


            setHelpdeskOptionDocumentation(lastToExpire.licence.options.documentation);
            setHelpdeskOptionControl(lastToExpire.licence.options.control);

            setHelpdeskLicenseEndDate(new Date(lastToExpire.expires_at));
            setHelpdeskLicenseEndInDays(Math.round((new Date(lastToExpire.expires_at).getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

            const isLicenseValid = new Date(lastToExpire.expires_at).getTime() > new Date().getTime();
            setHelpdeskCanCreateResources(isLicenseValid && remainingResources > 0);
        }
    }, [data]);


    return {
        helpdeskLicense,
        helpdeskRemainingResources,
        helpdeskCanCreateResources,
        helpdeskOptionDocumentation,
        helpdeskOptionControl,
        helpdeskLicenseEndDate,
        helpdeskLicenseEndInDays,
        licenceLoading: loading,
    };

}