import { Container } from "@scanow/ui";
import { Menu } from "@/components/Navigation";

export interface IHeader {}

const Header = ({}: IHeader) => {

  return (
    <header
      className={
        "relative w-full z-40 bg-white border-b border-neutral-200 dark:bg-neutral-900 dark:border-neutral-800"
      }
    >
      <nav className={`z-10`}>
        <Container className="px-10 relative py-5 flex justify-between items-center space-x-4 xl:space-x-8">
          <Menu />
        </Container>
      </nav>
    </header>
  );
}; 

export default Header;
