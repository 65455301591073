import { IItemMenu, IMegaItem } from "@/interfaces/navigation";
import {
  HiOutlineBuildingOffice2,
  HiOutlineUserGroup,
  HiOutlineUsers,
  HiOutlineServerStack,
  HiOutlineBell,
  HiOutlineChartPie,
  HiHandRaised,
  HiOutlineFolderOpen,
  HiOutlineBellSlash,
  HiOutlineDeviceTablet,
  HiOutlineSquares2X2,
  HiOutlineMapPin,
  HiOutlineUser,
  HiOutlineCog8Tooth,
  HiOutlineLifebuoy,
  HiOutlineCalendarDays,
} from "react-icons/hi2";

export function IsActive(pathname: String, item: IItemMenu): boolean {
  if (item.href === pathname) {
    return true;
  }

  var url = pathname.split("/");
  url.pop();
  var newUrl = url.join("/");
  console.log(newUrl);
  if (newUrl === item.href) {
    return true;
  }

  return false;
}
 
export function MainNavigation() {
  const menu: IItemMenu[] = [
    {
      href: "/admin/user/profile",
      icon: <HiOutlineUser className="w-6 h-6" />,
      name: "Mon profil",
      // description: t("navigation.private.dashboard"),
      exact: true,
      min_role: 3,
    },
    // {
    //   href: "/admin/user/stats",
    //   icon: <HiOutlineChartBarSquare className="w-6 h-6" />,
    //   name: "Mes statistiques",
    //   // description: t("navigation.private.dashboard"),
    //   exact: true,
    //   min_role: 3,
    // },
    {
      href: "/admin/settings",
      icon: <HiOutlineCog8Tooth className="w-6 h-6" />,
      name: "Paramètres",
      // description: t("navigation.private.dashboard"),
      exact: true,
      min_role: 3,
    },
    // {
    //  href: "/admin/help",
    //   icon: <HiOutlineLifebuoy className="w-6 h-6" />,
    //   name: "Aide",
    // description: t("navigation.private.dashboard"),
    //   exact: true,
    //   min_role: 3,
    //  },
  ];

  return menu;
}

export function SidebarNavigation() {
  const menu: IItemMenu[] = [
    {
      href: "/admin/dashboard",
      //path: "/admin/dashboard",
      icon: <HiOutlineChartPie className="w-5 h-5" />,
      name: "Tableau de bord",
      // description: t("navigation.private.dashboard"),
      exact: true,
      min_role: 3,
    },
    {
      href: "/admin/company",
      //path: "/admin/company",
      icon: <HiOutlineBuildingOffice2 className="w-5 h-5" />,
      name: "Entreprises",
      // description: t("navigation.private.company"),
      min_role: 1,
    },
    {
      href: "/admin/team",
      //path: "/admin/team",
      icon: <HiOutlineUserGroup className="w-5 h-5" />,
      name: "Équipes",
      // description: t("navigation.private.team"),
      min_role: 2,
    },
    {
      href: "/admin/location",
      //path: "/admin/location",
      icon: <HiOutlineMapPin className="w-5 h-5" />,
      name: "Localités",
      // description: t("navigation.private.location"),
      min_role: 3,
    },
    {
      href: "/admin/category",
      //path: "/admin/category",
      icon: <HiOutlineSquares2X2 className="w-5 h-5" />,
      name: "Catégories",
      //  description: t("navigation.private.category"),
      min_role: 3,
    },
    {
      href: "/admin/equipment",
      //path: "/admin/equipment",
      icon: <HiOutlineDeviceTablet className="w-5 h-5" />,
      name: "Équipements",
      // description: t("navigation.private.item"),
      min_role: 3,
    },
    {
      href: "/admin/resource",
      //path: "/admin/resource",
      icon: <HiOutlineServerStack className="w-5 h-5" />,
      name: "Ressources",
      // description: t("navigation.private.resource"),
      min_role: 3,
    },
    {
      href: "/admin/incident",
      //path: "/admin/incident",
      icon: <HiOutlineBell className="w-5 h-5" />,
      name: "Incidents",
      // description: t("navigation.private.incident"),
      min_role: 3,
    },
    {
      href: "/admin/incident_close_type",
      icon: <HiOutlineBellSlash className="w-5 h-5" />,
      name: "Causes de cloture",
      min_role: 3,
    },
    {
      href: "/admin/user",
      icon: <HiOutlineUsers className="w-5 h-5" />,
      name: "Utilisateurs",
      min_role: 2,
    },
    {
      href: "/admin/control",
      //path: "/admin/control",
      icon: <HiOutlineCalendarDays className="w-5 h-5" />,
      name: "Maintenances",
      // description: t("navigation.private.control"),
      min_role: 3,
    },
    {
      href: "/admin/documentation",
      //path: "/admin/documentation",
      icon: <HiOutlineFolderOpen className="w-5 h-5" />,
      name: "Documentation",
      // description: t("navigation.private.documentation"),
      min_role: 3,
    },
  ];
  // .filter((r) => r.min_role >= auth.auth.scanow_role_weight); // pour min role : admin=1 manageur=2 agent=3, 1>2>3

  // //Si la company n'a pas la documentation
  // if (!auth.auth.licence?.options.documentation) {
  //   menu = menu.filter((r) => r.path != "/admin/documentation");
  // }
  // //Si la company n'a pas le controle
  // if (!auth.auth.licence?.options.control) {
  //   menu = menu.filter((r) => r.path != "/admin/control");
  // }
  return menu;
}

export const PUBLIC_FOOTER_LINKS: IMegaItem[] = [
  {
    title: "Getting started",
    items: [
      { href: "#", slug: "my-page", name: "Installation" },
      { href: "#", slug: "my-page", name: "Release Notes" },
      { href: "#", slug: "my-page", name: "Upgrade Guide" },
      { href: "#", slug: "my-page", name: "Browser Support" },
      { href: "#", slug: "my-page", name: "Editor Support" },
      { href: "#", slug: "my-page", name: "Dark Mode" },
    ],
  },
  {
    title: "Explore",
    items: [
      { href: "#", slug: "my-page", name: "Design features" },
      { href: "#", slug: "my-page", name: "Prototyping" },
      { href: "#", slug: "my-page", name: "Design systems" },
      { href: "#", slug: "my-page", name: "Pricing" },
      { href: "#", slug: "my-page", name: "Customers" },
      { href: "#", slug: "my-page", name: "Security" },
    ],
  },
  {
    title: "Resources",
    items: [
      { href: "#", slug: "my-page", name: "Best practices" },
      { href: "#", slug: "my-page", name: "Support" },
      { href: "#", slug: "my-page", name: "Developers" },
      { href: "#", slug: "my-page", name: "Learn design" },
      { href: "#", slug: "my-page", name: "What's new" },
      { href: "#", slug: "my-page", name: "Releases" },
    ],
  },
  {
    title: "Community",
    items: [
      { href: "#", slug: "my-page", name: "Discussion Forums" },
      { href: "#", slug: "my-page", name: "Code of Conduct" },
      { href: "#", slug: "my-page", name: "Community Resources" },
      { href: "#", slug: "my-page", name: "Contributing" },
      { href: "#", slug: "my-page", name: "Concurrent Mode" },
      { href: "#", slug: "my-page", name: "API Reference" },
    ],
  },
];

export const PRIVATE_FOOTER_LINKS: IMegaItem[] = [
  {
    title: "Dashboard",
    items: [
      { href: "#", slug: "my-page", name: "Installation" },
      { href: "#", slug: "my-page", name: "Release Notes" },
      { href: "#", slug: "my-page", name: "Upgrade Guide" },
      { href: "#", slug: "my-page", name: "Browser Support" },
      { href: "#", slug: "my-page", name: "Editor Support" },
      { href: "#", slug: "my-page", name: "Dark Mode" },
    ],
  },
  {
    title: "Statistiques",
    items: [
      { href: "#", slug: "my-page", name: "Design features" },
      { href: "#", slug: "my-page", name: "Prototyping" },
      { href: "#", slug: "my-page", name: "Design systems" },
      { href: "#", slug: "my-page", name: "Pricing" },
      { href: "#", slug: "my-page", name: "Customers" },
      { href: "#", slug: "my-page", name: "Security" },
    ],
  },
  {
    title: "Resources",
    items: [
      { href: "#", slug: "my-page", name: "Best practices" },
      { href: "#", slug: "my-page", name: "Support" },
      { href: "#", slug: "my-page", name: "Developers" },
      { href: "#", slug: "my-page", name: "Learn design" },
      { href: "#", slug: "my-page", name: "What's new" },
      { href: "#", slug: "my-page", name: "Releases" },
    ],
  },
  {
    title: "Aide",
    items: [
      { href: "#", slug: "my-page", name: "Discussion Forums" },
      { href: "#", slug: "my-page", name: "Code of Conduct" },
      { href: "#", slug: "my-page", name: "Community Resources" },
      { href: "#", slug: "my-page", name: "Contributing" },
      { href: "#", slug: "my-page", name: "Concurrent Mode" },
      { href: "#", slug: "my-page", name: "API Reference" },
    ],
  },
];

export type { IItemMenu as IItem };
