import Copyright from "./Copyright";

export interface IFooter {}

const Footer = ({}: IFooter) => {
  return (
    <footer className={"mt-12 border-t border-neutral-200 dark:border-neutral-800"}>
      <nav className={`py-4`}>
        <Copyright />
      </nav>
    </footer>
  );
};

export default Footer;
