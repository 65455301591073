import { gql, TypedDocumentNode } from '@apollo/client';
import { IScanowLicense } from './interface';


interface ScanowGetLicenseQueryVariables {
  company_id: string;
}

interface ScanowHelpdeskGetLicenseQueryResponse {
  administration_licence: IScanowLicense[];
  administration_company_by_pk: {
    resources_aggregate: {
      aggregate: {
        count: number;
      }
    }
  };
}

export const SCANOW_HELPDESK_GET_LICENSE_QUERY: TypedDocumentNode<ScanowHelpdeskGetLicenseQueryResponse, ScanowGetLicenseQueryVariables> = gql`
    query ScanowHelpdeskGetLicense($company_id: uuid!) {
        administration_licence(where: {company_id: {_eq: $company_id}}) {
            id
            created_at
            expires_at
            company_id
            licence
        }
        administration_company_by_pk(id: $company_id) {
            resources_aggregate(where: {deleted: {_eq: false}}) {
                aggregate {
                    count
                }
            }
        }
    }
`;


interface ScanowInventoryAssetGetLicenseQueryResponse {
  administration_licence: IScanowLicense[];
  administration_company_by_pk: {
    sonar_items_aggregate: {
      aggregate: {
        count: number;
      }
    }
    asset_users_aggregate: {
      aggregate: {
        count: number;
      }
    }
  };
}

export const SCANOW_INVENTORY_ASSET_LICENSE_QUERY: TypedDocumentNode<ScanowInventoryAssetGetLicenseQueryResponse, ScanowGetLicenseQueryVariables> = gql`
    query ScanowInventoryAssetGetLicense($company_id: uuid!) {
        administration_licence(where: {company_id: {_eq: $company_id}}) {
            id
            created_at
            expires_at
            company_id
            licence
        }
        administration_company_by_pk(id: $company_id) {
            sonar_items_aggregate{
                aggregate{
                    count
                }
            }
            asset_users_aggregate{
                aggregate{
                    count
                }
            }
        }
    }
`;



