import React from "react";
import { Container, FeedbackButton } from "@scanow/ui";
import * as Sentry from "@sentry/nextjs";
import { useAuth } from "@/helpers/contexts/auth";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { Sidebar } from "../Navigation";

interface IPrivateLayout {
  children: React.ReactNode;
  className?: string;
  headBar?: React.ReactNode;
}

export const PrivateLayout = ({
  children,
  className = "",
  headBar,
}: IPrivateLayout) => {
  const auth = useAuth();

  return (
    <main>
      <FeedbackButton
        onClick={() => {
          const err = new Error("User feedback");
          err.name = "User feedback";
          Sentry.captureException(err, {
            level: "warning",
            tags: { component: "feedback", layout: "private" },
          });
          Sentry.showReportDialog({
            title: "Votre avis",
            subtitle: "Un problème ? Une suggestion ?",
            subtitle2:
              "Aidez-nous aider à améliorer Scanow en remplissant ce formulaire.",
            user: {
              email: auth.auth.user?.email,
              name:
                auth.auth.user?.firstname +
                " " +
                auth.auth.user?.lastname.toUpperCase(),
            },
          });
        }}
      /> 
      <Header />
      <Container className="px-2 lg:px-6 my-14 lg:my-18">
        <div className={`relative`}>
          {headBar && (
            <div className="mb-6 bg-white dark:bg-neutral-900 dark:border dark:border-neutral-800 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-md flex flex-col md:flex-row lg:items-center">
              {headBar}
            </div>
          )}
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-6">
            <aside className="hidden lg:block">
              <nav className="sticky top-4 border border-neutral-200 dark:border-neutral-800 rounded-xl">
                <Sidebar />
              </nav>
            </aside>
            <div className="grid-cols-1 lg:col-span-5">
              {children}
              <Footer />
            </div>
          </div>
        </div>
      </Container>
    </main>
  );
};
