import React from "react";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoGithub,
  IoLogoTwitter,
  IoLogoLinkedin,
} from "react-icons/io5";
import {FACEBOOK_URL, LINKEDIN_URL} from "@scanow/config";

export interface ISocials {
  name: string;
  icon: any;
  href: string;
}

const socials: ISocials[] = [
  {
    name: "Facebook",
    icon: <IoLogoFacebook className="w-6 h-6" />,
    href: FACEBOOK_URL,
  },
  {
    name: "LinkedIn",
    icon: <IoLogoLinkedin className="w-6 h-6" />,
    href: LINKEDIN_URL,
  },
  /*{
    name: "Twitter",
    icon: <IoLogoTwitter className="text-base sm:text-xl" />,
    href: TWITTER_URL,
  },
  {
    name: "Instagram",
    icon: <IoLogoInstagram className="text-base sm:text-xl" />,
    href: INSTAGRAM_URL,
  },
  {
    name: "Github",
    icon: <IoLogoGithub className="text-base sm:text-xl" />,
    href: GITHUB_URL,
  },*/
];

const SocialsList = () => {
  const renderItem = (item: ISocials, index: number) => {
    return (
      <li key={index}>
        {" "}
        <a
          href={item.href}
          target="_blank"
          className="w-7 h-7 md:w-9 md:h-9 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:bg-transparent dark:hover:text-white cursor-pointer"
          rel="noreferrer noopener"
        >
          {item.icon}
        </a>
      </li>
    );
  };

  return (
    <ul 
    //className={`flex space-x-1.5 text-neutral-700 dark:text-neutral-300`}
    className="inline-flex justify-center mt-4 space-x-3 sm:ml-auto sm:mt-0 sm:justify-start"
    >
      {socials.map(renderItem)}
    </ul>
  );
};

export default SocialsList;
