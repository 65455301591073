import { Popover, Transition } from "@headlessui/react";
import { useState, useEffect, Fragment } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Avatar } from "@scanow/ui";
import { useCookies } from "react-cookie";
import userAuth from "@/helpers/auth";
import { IsActive, MainNavigation } from "@/components/Navigation";
import { IItemMenu } from "@/interfaces/navigation";
import { IoPowerOutline } from "react-icons/io5";


interface IMenuItemActive extends IItemMenu {
  active: boolean;
}

export const DropdownUser = () => {
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);
  const auth = userAuth();
  const [menu, setMenu] = useState<IMenuItemActive[]>([]);
  const router = useRouter();
  useEffect(() => {
    const menu = MainNavigation();
    const pathname = router.pathname;
    const newMenu = menu.map((item) => {
      return {
        ...item,
        active: IsActive(pathname, item),
      };
    });
    setMenu(newMenu);
  }, [router.pathname]);

  return (
    <section>
      <Popover className="relative h-9">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar
                appendClassName="w-9 h-9 my-auto"
                src={auth.user?.avatar_url || "/images/no_avatar.png"}
                alt={
                  auth.user
                    ? `${
                        auth.user.firstname
                      } ${auth.user.lastname.toUpperCase()}`
                    : "John Doe"
                }
                title={
                  auth.user
                    ? `${
                        auth.user.firstname
                      } ${auth.user.lastname.toUpperCase()}`
                    : "John Doe"
                }
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-3 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <ul className="relative grid grid-cols-1 gap-6 bg-white dark:bg-neutral-800 py-7 px-6">
                    <li className="flex items-center space-x-3">
                      <div className="flex-grow">
                        <h4 className="font-semibold">
                          {auth.user
                            ? `${auth.user.firstname} ${auth.user.lastname}`
                            : "John Doe"}
                        </h4>
                        <p className="text-xs mt-0.5 text-indigo-600 ">
                          {auth.scanow_role_weight === 1 && "Administrateur"}
                          {auth.scanow_role_weight === 2 && "Manageur"}
                          {auth.scanow_role_weight === 3 && "Agent"}
                        </p>
                      </div>
                    </li>

                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                    {menu.map((item) => (
                      <Link
                        href={{
                          pathname: item.href || undefined,
                        }}
                        key={item.name}
                        rel="noopener noreferrer"
                      >
                        <a
                          className={`flex items-center p-2 -m-3 transition duration-300 ease-in-out rounded-lg hover:text-white hover:bg-indigo-600 dark:hover:bg-neutral-700 ${item.active && "active bg-indigo-600 text-white dark:bg-neutral-700"}`}
                        >
                          <div className="flex items-center justify-center flex-shrink-0">
                            {item.icon}
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium ">{item.name}</p>
                          </div>
                        </a>
                      </Link>
                    ))}

                    <Link href="#">
                      <a
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg text-danger-600 hover:text-danger-500 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-50"
                        onClick={(e) => {
                          e.preventDefault();
                          removeCookie("accessToken", { path: "/" });
                          if (typeof window !== "undefined") {
                            localStorage.clear();
                          }

                          router.push("/auth/signin");
                        }}
                      >
                        <div className="flex items-center justify-center flex-shrink-0">
                          <IoPowerOutline className="w-6 h-6 text-danger-600" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">
                            {"Se déconnecter"}
                          </p>
                        </div>
                      </a>
                    </Link>
                  </ul>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </section>
  );
};
