import React from "react";
import Link from "next/link";
import { useCookies } from "react-cookie";
import { useAuth } from "@/helpers/contexts/auth";
import { Logo, Button } from "@scanow/ui";
import { Disclosure } from "@headlessui/react";
import { Sidebar } from "@/components/Navigation";
import { IItemMenu } from "@/interfaces/navigation";
import { HiXMark } from "react-icons/hi2";
import { IoPowerOutline } from "react-icons/io5";
import { useRouter } from "next/router";
 
export interface IMobileNavigation {
  data?: IItemMenu[];
  onClickClose?: () => void;
}

export const MobileNavigation = ({ onClickClose }: IMobileNavigation) => {
  const auth = useAuth();
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);
  const router = useRouter();
  return (
    <aside className="overflow-y-auto max-w-sm h-screen transition transform bg-white dark:bg-neutral-900 z-30 ">
      <header>
        <div className="flex justify-between items-center m-4">
        <Logo width="w-[150px]" />
          <Button.Circle onClick={onClickClose}>
            <HiXMark />
          </Button.Circle>
        </div>
      </header>

      <Disclosure>
        <nav className="top-4">
          <Sidebar />
          <Link href="#">
          <a
            className="flex items-center px-4 py-4 transition duration-150 ease-in-out rounded-lg text-danger-600 hover:text-danger-500 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-50"
            onClick={(e) => {
              e.preventDefault();
              removeCookie("accessToken", { path: "/" });
              if (typeof window !== "undefined") {
                localStorage.clear();
              }

              router.push("/auth/signin");
            }}
          >
            <div className="flex items-center justify-center flex-shrink-0">
              <IoPowerOutline className="w-6 h-6 text-danger-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium ">{"Se déconnecter"}</p>
            </div>
          </a>
        </Link>
        </nav>
      </Disclosure>
      <footer className="bottom-0">
      
        {/*<Button.Variant
        style="Wide"
        size="Large"
        shape="Square"
        color="Danger"
          onClick={(e) => {
            e.preventDefault();
            removeCookie("accessToken", { path: "/" });
            if (typeof window !== "undefined") {
              localStorage.clear();
            }

            router.push("/auth/signin");
          }}
         // className="w-full rounded-none bg-red-100 hover:bg-red-200 text-red-500"
        
        >
          <IoPowerOutline className="w-7 h-7 pr-2" /> Se déconnecter
        </Button.Variant>*/}
      </footer>
    </aside>
  );
};
