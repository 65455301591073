import React from "react";
import { Container} from "@scanow/ui";

interface IPublicLayout {
  children: React.ReactNode;
  className?: string;
}

export const PublicLayout = ({ children, className = "" }: IPublicLayout) => {
  return (
    <main>
     <Container className="px-2 lg:px-6 my-14 lg:my-18">
      {children}
      </Container>
    </main>
  );
}; 

