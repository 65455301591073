import * as React from "react";
import Socials from "@/components/Shared/Socials";
import { APP_MODULE } from "@/config";
import {
  APP_NAME,
  APP_VERSION,
  APP_AUTHOR,
  APP_AUTHOR_URL,
} from "@scanow/config";
import { useTranslation } from "next-i18next";

const Copyright = () => {
  const { t } = useTranslation("common");
  return (
    <section className="flex flex-col items-center sm:flex-row py-4">
      <p className="text-xs text-neutral-600">
        © {new Date().getFullYear()} {APP_NAME} {APP_MODULE} {APP_VERSION} |{" "}
        {t("footer.rights")} | Web artisan{" "}
        <a
          href={APP_AUTHOR_URL}
          target="_blank"
          rel="noreferrer"
          className="border-b text-info-500"
        >
          {APP_AUTHOR}
        </a>
      </p>
      <Socials />
    </section>
  );
};

export default Copyright;
